import React from 'react'

import { Layout, Nav, Typography, Flex, ThreeBackground, Seo, Rodo } from '../components/'
const rodo = ({ data }) => {
	return (
		<Layout>
			<Seo lang="pl" title={'/ ' + 'polityka prywatności'} />
			<Nav />
			<ThreeBackground />
			<Rodo />
		</Layout>
	)
}

export default rodo
